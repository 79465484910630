















































































































































































import { Component, Vue } from 'vue-property-decorator'
import FileDetailLayout from '@/views/Group/TopPage/SharedFolder/FileDetailLayout.vue'
import {
  ShareFolderFileButtonState,
  State,
  FileDetail,
  EFileFormat,
  EFileError
} from '@/models'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import CommonSharedFolderService from '@/services/CommonSharedFolderService'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'

/**
 * f2-603
 * サークル共有フォルダ - ファイルの上書き
 */
@Component({ components: { FileDetailLayout } })
export default class FileOverwrite extends Vue {
  private isCommonTopic = !this.$route.params.groupId
  private actionAreaState: ShareFolderFileButtonState = new ShareFolderFileButtonState()
  private file: FileDetail = new FileDetail()
  private fileChoose!: File
  private comment: string = ''
  private notifyUpdate: boolean = false
  private chooseFileName: string = String(this.$t('common.form.not_selected'))
  private displayChooseFile: string = ''
  private eFileFormat = EFileFormat
  private ehumbNailsDisplay = EhumbNailsDisplay
  private fileErrors: EFileError = EFileError.NONE
  private eFileError = EFileError
  private maxFileSize: any = {
    mb: this.isCommonTopic
      ? 25
      : Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes: this.isCommonTopic
      ? 25 * 1048576
      : Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
  }
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private modalMess: string = ''
  private modalAction: any = () => {}

  created() {
    this.handleButtonState()
    this.getFileDetail()
    this.handleMaxFileSize()
  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes:
        Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
    }
  }

  checkRouterBeforLeave() {
    return this.comment === this.file.memo && !this.fileChoose
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.confirmLeave = next
      this.$bvModal.show('file-overwrite-modal-confirm')
    } else {
      next()
    }
  }

  /**
   * Check role edit
   */
  checkRoleEdit() {
    if (!(this.$refs.fileDetailLayout as any).roleEditFile) {
      this.confirmLeaveIgnore = true
      this.jumpToFileDetail()
    }
  }

  /**
   * Call API get file detail
   */
  getFileDetail() {
    setTimeout(async () => {
      await (this.$refs.fileDetailLayout as any).getFileDetail()
      this.checkRoleEdit()
      this.file = (this.$refs.fileDetailLayout as any).currentFile
      this.comment = this.file.memo
    }, 1)
  }

  handleButtonState() {
    this.actionAreaState = new ShareFolderFileButtonState({
      fileOverwrite: State.DISABLE,
      updateInfo: State.ACTIVE,
      moveFolder: State.DISABLE,
      moveToTrash: State.HIDDEN
    })
  }

  onFileChange(event: any) {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return
    if (files[0].size > this.maxFileSize.bytes) {
      this.fileErrors = EFileError.OVER_SIZE
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-ovewrite')
      return
    }
    this.fileErrors = EFileError.NONE
    this.fileChoose = files[0]
    this.chooseFileName = files[0].name
    this.displayChooseFile = URL.createObjectURL(files[0])
  }

  /**
   * Get file format by file extension
   */
  getFileFormat() {
    return CheckFileFormat.getFileFormatWithFileName(this.chooseFileName)
  }

  /**
   * Get fontawsome icon by file extension
   */
  getIconName() {
    return CheckFileFormat.getIconClassNameWithFileName(this.chooseFileName)
  }

  submitOverwriteFile() {
    if (this.fileChoose) {
      let formData = new FormData()
      formData.set('groupId', this.$route.params.groupId)
      formData.set('fileId', this.$route.params.fileId)
      formData.set('file', this.fileChoose)
      formData.set('memo', this.comment ? this.comment : '')
      formData.set('notifyUpdate', String(this.notifyUpdate))
      this.$blockui.show()
      if (this.isCommonTopic) {
        CommonSharedFolderService.overwriteCommonFile(formData)
          .then(res => {
            if (res.status === 200) {
              this.confirmLeaveIgnore = true
              this.fileErrors = EFileError.NONE
              this.$bvModal.show('modal-success-ovewrite')
            }
          })
          .catch(err => {
            if (err.response.status === 404) {
              this.modalMess = this.$t(
                'common.message.file_not_exist'
              ) as string
              this.modalAction = () => this.jumpBack()
              this.confirmLeaveIgnore = true
            } else {
              this.modalMess = this.$t('common.message.update_fail') as string
              this.modalAction = () => {}
            }
            this.$bvModal.show('modal-error-ovewrite')
          })
          .finally(() => this.$blockui.hide())
      } else {
        GroupSharedFolderService.overwriteFile(formData)
          .then(res => {
            if (res.status === 200) {
              this.confirmLeaveIgnore = true
              this.fileErrors = EFileError.NONE
              this.$bvModal.show('modal-success-ovewrite')
            }
          })
          .catch(err => {
            if (err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY') {
              this.modalMess = this.$t(
                'common.message.group_over_size'
              ) as string
              this.modalAction = () => {}
            } else if (err.response.status === 404) {
              this.modalMess = this.$t(
                'common.message.file_not_exist'
              ) as string
              this.modalAction = () => this.jumpBack()
              this.confirmLeaveIgnore = true
            } else {
              this.modalMess = this.$t('common.message.update_fail') as string
              this.modalAction = () => {}
            }
            this.$bvModal.show('modal-error-ovewrite')
          })
          .finally(() => this.$blockui.hide())
      }
    } else {
      this.fileErrors = EFileError.EMPTY
    }
  }

  formatBytes(bytes: number) {
    if (bytes == 0) return '0 KB'
    if (bytes < 1) return '1 KB'
    const k = 1024
    const sizes = ['KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
  }

  jumpToParentTopic() {
    if (this.file.topic) {
      if (this.isCommonTopic) {
        this.$router.push({
          name: 'common-topic-new-feed-topic-detail',
          params: {
            topicId: String(this.file.topic.id)
          }
        })
      } else {
        this.$router.push({
          name: 'group-new-feed-topic-detail',
          params: {
            topicId: String(this.file.topic.id)
          }
        })
      }
    }
  }

  jumpToFileDetail() {
    if (this.isCommonTopic) {
      this.$router.push({ name: 'common-topic-shared-folder-file-detail' })
    } else {
      this.$router.push({ name: 'group-shared-folder-file-detail' })
    }
  }

  /**
   * Jump back
   */
  jumpBack() {
    if (this.isCommonTopic) {
      this.$router.push({
        name: 'common-topic-shared-folder'
      })
    } else {
      this.$router.push({
        name: 'group-shared-folder'
      })
    }
  }
}
